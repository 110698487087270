.clients {
    /* margin-top: 36px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.clients .client-image {
    width: 24%;
    margin-bottom: 30px;
}

@media (width < 650px) {
    .horizontal-gallery.clients {
        display: flex;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        height: 130px;
        scrollbar-width: none;
        margin-left: 15px;
        margin-right: 15px;
    }

    .horizontal-gallery.clients > div {
        flex-wrap: nowrap;
    }

    .horizontal-gallery.clients .client-image {
        width: 130px!important;
        margin-bottom: 0;
    }
    
    .horizontal-gallery.clients::-webkit-scrollbar {
        display: none;
    }
    
    .horizontal-gallery.clients img,
    .horizontal-gallery.clients .ant-image {
        height: 130px;
        object-fit: contain;
        min-width: auto;
    }
    
    .horizontal-gallery.clients .ant-col {
        flex: 0 0 auto;
        padding-right: 10px;
    }
}


@media (width < 450px) {

}