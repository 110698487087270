.reviews {
    flex-direction: column;
    /* margin-top: 36px; */
    margin-bottom: 20px;
}

.reviews img {
    border-radius: 10px;
}

.reviews1,
.reviews2  {
    justify-content: space-between;
    margin-bottom: 48px;
    margin-left: 0!important;
    margin-right: 0!important;
}

.reviews1 > div,
.reviews2 > div {
    flex: 1 1;
    padding: 0!important;
}

.reviews1 {
    column-gap: 5vw;
}

.reviews-small {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
}

@media (width < 650px) {
    .horizontal-gallery.reviews {
        display: flex;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        height: 130px;
        scrollbar-width: none;
        margin-left: 15px;
        margin-right: 15px;
    }
    
    .horizontal-gallery.reviews::-webkit-scrollbar {
        display: none;
    }
    
    .horizontal-gallery.reviews img,
    .horizontal-gallery.reviews .ant-image {
        max-height: 130px;
        object-fit: contain;
        min-width: auto;
    }
    
    .horizontal-gallery.reviews .ant-col {
        flex: 0 0 auto;
        padding-right: 10px;
    }
}
