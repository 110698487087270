
.main-search {
    width: 100%;
    max-width: 600px;
    min-width: 80%;
    border: none;
}

.main-search input {
    background-color: transparent !important;
    color: #fff;
    font-size: 24px !important;
    font-weight: 400 !important;
}

.main-search .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    color: #fff !important;
    box-shadow: none !important;
}

.search-button {
    font-size: 24px;
    font-weight: 400;
    background-color: transparent !important;
    border: 0px;
    color: #fff;
    outline: none !important;
}

.search-button span{
    color: #fff !important;
}

.search-button button:focus {
    outline: none !important;
}

@media (width < 1100px) {
    .main-search {
        line-height: 50px;
    }

    .main-search img {
        height: 20px;
        width: 20px;
    }

    .main-search .ant-select {
        height: 30px;
    }

    .main-search .ant-select-selector input {
        font-size: 18px!important;
        line-height: 18px!important;
        align-content: flex-end;        
    }


    .main-search > .ant-col:nth-last-child(1) {
        display: flex;
        justify-content: flex-end;
    }

    .main-search .ant-input-wrapper > span {
        padding: 0!important;
        font-size: 18px!important;
        height: 30px;
    }

    .main-search input {
        font-size: 14px!important;
        color: #fff!important;
    }

    .main-search button {
        font-size: 16px!important;
        padding: 0!important;
        height: auto!important;
    }
}

@media (width < 750px) {

    .main-search {
        line-height: 32px;
    }

    .main-search img {
        height: 15px;
        width: 15px;
    }

    .main-search .ant-select {
        height: 30px;
    }

    .main-search .ant-select-selector input {
        font-size: 14px!important;
        line-height: 14px!important;
        align-content: flex-end;        
    }


    .main-search > .ant-col:nth-last-child(1) {
        display: flex;
        justify-content: flex-end;
    }

    .main-search .ant-input-wrapper > span {
        padding: 0!important;
        font-size: 14px!important;
        height: 20px;
    }

    .main-search input {
        font-size: 14px!important;
        color: #fff!important;
    }

    .main-search button {
        font-size: 14px!important;
        padding: 0!important;
        height: auto!important;
    }
}

@media (width < 450px) {

    .main-search {
        line-height: 18px;
    }

    .main-search img {
        height: 10px;
        width: 10px;
    }

    .main-search .ant-select {
        height: 24px;
    }

    .main-search .ant-select-selector input {
        font-size: 12px!important;
        line-height: 12px!important;
        align-content: flex-end;        
    }

    .main-search .ant-input-wrapper > span {
        padding: 0!important;
        height: 20px;
    }

    .main-search span {
        font-size: 14px!important;
    }

    .main-search input {
        font-size: 14px!important;
        color: #fff!important;
    }

    .main-search button {
        font-size: 14px!important;
        padding: 0!important;
        height: auto!important;
    }
}