.not-found-page {
    background-color: #fff;
    padding-top: 50px;
    padding-bottom: 100px;
    height: 100%;
}

.not-found-page > * {
    justify-content: center;
}

.go-to-main-button {
    line-height: normal;
    height: auto;
    padding: 10px 40px;
    font-size: 40px;
    font-weight: 300;
    box-shadow: 10px 15px 10px 0px  rgba(0,0,0,.25);
}

.go-to-main-button:hover {
    opacity: 0.8;
}

.go-to-main-button:hover a{
    color: #fff;
    text-decoration: none;
}

.go-to-main-button:focus {
    outline: none;
}

.not-found-page-content {
    display: flex;
    justify-content: center;
    padding: 10px;
    
}

.not-found-page-content span{
    text-shadow: 10px 10px 10px rgba(0,0,0,.25) !important;
}

html:has(.not-found-page),
body:has(.not-found-page),
body:has(.not-found-page) #root,
body:has(.not-found-page) #root > div,
body:has(.not-found-page) #root > div > div,
body:has(.not-found-page) #root > div > div > div {
    height: 100%;
}

main:has(.not-found-page) {
    flex: 1;
    min-height: auto!important;
}

@media (max-width: 1250px) {
    .not-found-page {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .not-found-page_title span {
        font-size: 80px!important;
    }

    .not-found-page_texterror span {
        font-size: 30px!important;
    }

    .go-to-main-button {
        padding: 6px 30px;
        font-size: 30px;
    }
}

@media (max-width: 800px) {
    .not-found-page {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .not-found-page_title span {
        font-size: 60px!important;
    }

    .not-found-page_texterror span {
        font-size: 22px!important;
    }

    .go-to-main-button {
        padding: 6px 20px;
        font-size: 22px;
    }
}

@media (max-width: 600px) {
    .not-found-page {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .not-found-page_title span {
        font-size: 40px!important;
    }

    .not-found-page_texterror span {
        font-size: 18px!important;
    }

    .go-to-main-button {
        padding: 6px 14px;
        font-size: 18px;
    }
}
@media (max-width: 450px) {
    .not-found-page {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .not-found-page_title span {
        font-size: 30px!important;
    }

    .not-found-page_texterror span {
        font-size: 16px!important;
    }

    .go-to-main-button {
        padding: 6px 14px;
        font-size: 16px;
    }
}