.main {
    background-color: #36322F;
    position: relative;
}

.button-all-goods {
    width: 100%;
    display: flex;
    justify-content: center;
}

.button-all-goods button {
    border-radius: 10px;
    background: #fff;
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.25);
    width: 530px;
    height: 60px;
}

.button-all-goods button:hover {
    background-color: #F67828 !important;
    border: 1px solid #F67828!important;
    
}

.button-all-goods button a {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-variant: small-caps;
    text-decoration: none
}

.button-all-goods button:hover a {
    color: #fff;
}

.button-all-goods button:active {
    color: #fff !important;
    border-color: #fff !important;
}

.main__avantages {
    color: #fff;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 24px;
}

.main__avantages .ant-card {
    color: #fff;
    height: 100%;
    background-color: transparent;
    border: none;
    margin-bottom: 60px;
}

.main__avantages .ant-card-body {
    padding: 0;
}

.main__avantages .title__avantages {
    color: #fff;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
}

.main__avantages .text__avantages {
    color: #fff;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 200;
}

.video__avantages {
    justify-content: space-between;
}

.accordions {
    flex-direction: column;
    padding-bottom: 40px;
}

.accordion {
    background-color: #fff;
    cursor: pointer;
    padding-top: 30px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #000;
    text-align: left;
    outline: none;
    font-size: 24px;
    font-weight: 300;
    transition: 0.5s;
}

.accordion:focus {
    outline: none;
}

.accordion:after {
    content: '\002B';
    font-size: 26px;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.accordion.active:after {
    content: "\02C5";
}

.panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.panel div {
    padding: 24px 28px;
    margin-top: 24px;
    background-color: #F0EFEF;
    border-radius: 10px;
}

.panel p {
    margin: 0;
    color: #212121;
    font-size: 24px;
}

.panel p a {
    color: #F67828;
}

button:disabled,
button:disabled a {
    background: linear-gradient(180deg, #818181 100%, #b7b7b7 0%)!important;
    opacity: 0.7;
    color: #fff!important;
}

.main-carousel .carousel__button:disabled:hover a {
    color: #fff;
}

@media (max-width: 1200px) {

    .main__avantages .ant-col {
        max-width: 100%;
    }

    .main__avantages .ant-card {
        height: auto;
        margin-bottom: 20px;
    }

    .main__avantages .title__avantages {
        font-size: 24px;
    }
    
    .main__avantages .text__avantages,
    .main__avantages .other__avantages {
        font-size: 20px;
    }

    .video__avantages {
        flex-direction: column-reverse;
    }

    .video__avantages > div {
        max-width: 100%;
        padding-top: 30px!important;
    }

    .video__avantages > div:nth-last-child(1) {
        height: 400px;
        flex: auto;
    }

    .button-all-goods button {
        width: 400px;
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .button-all-goods button a {
        font-size: 18px;
        line-height: 16px;
    }

    .accordion {
        padding-top: 20px;
        font-size: 20px;
    }

    .panel div {
        padding: 18px 20px;
        margin-top: 20px;
    }

    .panel p {
        font-size: 18px;
    }

}

@media (max-width: 950px) {

    .main__avantages {
        flex-direction: column;
        flex-wrap: nowrap;
        flex: auto;
    }

    .main__avantages .ant-col {
        max-width: 100%;
    }

    .main__avantages .ant-card {
        height: auto;
        margin-bottom: 20px;
    }

    .main__avantages .title__avantages {
        font-size: 20px;
    }
    
    .main__avantages .text__avantages,
    .main__avantages .other__avantages {
        font-size: 18px;
    }

    .video__avantages {
        flex-direction: column-reverse;
    }

    .video__avantages > div {
        max-width: 100%;
        padding-top: 30px!important;
    }

    .video__avantages > div:nth-last-child(1) {
        height: 300px;
        flex: auto;
    }

}

@media (width < 450px) {
    .accordions {
        padding-bottom: 40px;
    }
    
    .accordion {
        padding-top: 14px;
        font-size: 14px;
        color: #000;
    }
    
    .accordion:after {
        font-size: 16px;
    }
    
    .panel div {
        padding: 20px;
        margin-top: 15px;
    }
    
    .panel p{
        font-size: 14px;
    }

    .button-all-goods button {
        width: 132px;
        height: 30px;
    }

    .button-all-goods button a {
        font-size: 14px;
    }

    .main__avantages .title__avantages {
        font-size: 14px
    }

    .main__avantages .text__avantages {
        font-size: 12px;
    }

    .main__avantages .text__avantages,
    .main__avantages .other__avantages {
        font-size: 12px;
    }

    .main__avantages .ant-card {
        margin-bottom: 10px;
    }
    
    .main__avantages {
        font-size: 11px;
    }

}
