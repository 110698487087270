.feedback-checkbox {
    color: #FFF !important;
    opacity: 0.6;
}

.feedback-checkbox a{
    color: #fff !important;
}

.feedback-checkbox .ant-checkbox-inner {
    width: 30px !important;
    height: 30px !important;
}

.feedback-checkbox .ant-checkbox-inner::after {
    width: 50% !important;
    height: 50% !important;
    border: 3px solid #fff !important;
    border-top: 0 !important;
    border-inline-start: 0 !important;
}

.success-modal span{
    color: #FFF;
}



@media (width < 376px) {

    .feedback__form > div {
        margin: 0;
    }

    .feedback__form input {
        padding: 8px 112px;
        font-size: 14px;
        font-weight: 300;
        background-color: #fff !important;
        border: 2px solid #FFF;
        height: auto;
        margin-bottom: 10px;
    }

    .feedback__form .submit-button {
        width: 100% !important;
        height: auto;
        font-size: 14px;
        font-weight: 300;
        background-color: #F67828;
    }

    .feedback-checkbox .ant-checkbox-inner {
        width: 14px !important;
        height: 14px !important;
    }
}

.success-modal {
color: #fff;
height: 569px !important;
}
