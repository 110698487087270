.map {
    margin-top: 40px!important;
    position: relative;
    display: flex;
    justify-content: center;
}

.shadow_for_map_top {
    position: absolute;
    height: 60px;
    width: 100%;
    z-index: 9999;
    box-shadow: 0 20px 25px 0 inset;
    opacity: 0.4;
}

.shadow_for_map_bot {
    position: absolute;
    bottom: 0;
    height: 60px;
    width: 100%;
    z-index: 9999;
    box-shadow: 0 -20px 25px 0 inset;
    opacity: 0.4;
}

@media (max-width: 950px) {
    .map {
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 20px!important;
    }
}

@media (max-width: 450px) {
    .map {
        height: 152px;
        overflow: hidden;
        margin-top: 20px!important;
    }

    .map img{
        height: 152px!important;
        object-fit: cover;
    }
}