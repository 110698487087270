h1 svg,
h2 svg,
h3 svg {
    display: none!important;
}

h1.title__h2,
h2.title__h2 {
    font-family: "Oswald"!important;
    font-optical-sizing: auto;
    font-style: normal;
    width: auto;
    display: flex;
    justify-content: flex-start;
    margin-top: 60px!important;
    margin-bottom: 40px!important;
    font-size: 64px;
    font-weight: 300;
    color: #fff;
    border-bottom: 4px solid #F67828;
    line-height: 0;
}

@media (width < 1200px) {
    h2.title__h2 {
        font-size: 52px;
        margin-top: 50px!important;
        margin-bottom: 30px!important;
    }
}

@media (width < 900px) {
    h2.title__h2 {
        font-size: 50px;
        margin-top: 50px!important;
        margin-bottom: 30px!important;
    }
}

@media (width < 750px) {
    h2.title__h2 {
        font-size: 38px;
        margin-top: 60px!important;
        margin-bottom: 30px!important;
    }
}

@media (width < 450px) {
    h2.title__h2 {
        font-size: 24px;
        margin-top: 30px!important;
        margin-bottom: 15px!important;
    }
}