.product__col {
    padding: 0!important;
    margin-bottom: 80px;
}

.product__col a:hover{
    text-decoration: none;
}

.product__card {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    height: 100%;
    border: none;
    background: none;
}

.product__card:hover {
    box-shadow: none;
}

.product__card .ant-card-cover img {
    border-radius: 10px;
}

.product__card .ant-card-cover .ant-image {
    /* height: 300px!important; */
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.25);
}

.product__card .product__img {
    object-fit: contain;
    height: 100%;
}

.product__card .ant-card-body {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
}

.product__card .ant-card-body > div {
    padding: 20px 0;
    flex-grow: 1;
 }

.product__card .ant-card-body div p {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
}

.product__button {
    border: 1px solid #fff;
    border-radius: 10px;
    background: none;
    width: 100%;
    height: 60px;
    font-size: 24px;
    font-weight: 200;
}

.product__card:hover .product__button {
    border: 1px solid #F67828!important;
    background-color: #F67828!important;
}

.product__button:focus {
    outline: none;
}

.product__button:disabled {
    background: linear-gradient(180deg, #818181 100%, #b7b7b7 0%);
    opacity: 0.7;
    color: #fff!important;
}

@media (max-width: 1250px) {
    .product__col {
        max-width: 350px;
        min-width: 135px!important;
    }

    .product__card .ant-card-body > div {
        padding: 15px 0;
    }

    .product__card .ant-card-body > div p {
        font-size: 20px;
    }

    .product__button {
        font-size: 20px;
        height: 50px;
    }

}

@media (max-width: 1100px) {
    .product__col {
        max-width: 300px;
    }

    .product__card .ant-card-body > div {
        padding: 10px 0;
    }
}

@media (max-width: 950px) {
    .product__col {
        max-width: 400px;
    }
}

@media (max-width: 850px) {
    .product__col {
        max-width: 350px;
    }
}

@media (max-width: 750px) {
    .product__col {
        max-width: 300px;
    }
}

@media (max-width: 650px) {
    .product__col {
        max-width: 250px;
    }
    
    .product__card .ant-card-body > div p {
        font-size: 18px;
    }

    .product__button {
        font-size: 18px;
        height: 40px;
    }
}

@media (max-width: 550px) {
    .product__col {
        max-width: 200px;
    }
}

@media (max-width: 450px) {
    .product__col {
        max-width: 160px;
        min-width: 132px!important;
        margin-bottom: 30px;
    }

    .product__card .ant-card-body div {
        padding: 5px 0;
    }

    .product__card .ant-card-body div p {
        font-size: 12px;
    }

    .product__button {
        height: 30px;
        font-size: 14px;
        border-radius: 5px;
    }

}
