* {
    font-family: Roboto!important;
}

.content {
    width: auto;
    display: flex;
    margin-left: 0!important;
    margin-right: 0!important;
    padding-left: 70px;
    padding-right: 70px;
}

.breadCrumb {
    padding-top: 0;
    padding-bottom: 57px;
}

.breadCrumb * {
    color: #fff!important;
}

.breadCrumb a {
    opacity: 0.5;
}

.breadCrumb span {
    font-size: 16px;
}

.back-top-btn {
    color: transparent;
    opacity: 0.2;
}

.back-top-btn .ant-float-btn-icon {
    width: 100% !important;
}

@media (max-width: 1400px) {
    .content {
        padding-left: 20px;
        padding-right: 20px;
    }

    .breadCrumb span {
        font-size: 14px;
    }
}

@media (max-width: 500px) {

    .content {
        padding-left: 15px;
        padding-right: 15px;
    }

    header {
        padding: 0!important;
    }

    .breadCrumb {
        padding-bottom: 18px!important;
    }

    .breadCrumb span {
        font-size: 12px;
    }
}
