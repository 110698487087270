.call-back-button {
    border: 0px !important;
    width: 100%;
    min-width: 220px;
    height: 61px;
    font-size: 24px;
    color: #fff;
    padding: 8px 15px;
    font-weight: 400;
    background: #F67828;
}

.call-back-button a:hover {
    color: #000;
    text-decoration: none;
}

.call-back-button:hover {
    background-color: #fff !important;
    color: #000 !important;
}

.call-back-button:focus {
    outline: none;
}

.modal-main .ant-modal-content {
    height: 500px !important;
    background-color: rgba(54,50,47, 0.35) !important;
    backdrop-filter: blur(10px);
    color: #fff !important;
    text-align: center;
    padding-top: 60px;
}

.modal-title {
    color: #fff !important;
    font-family: Oswald !important;
    font-size: 48 !important;
    font-weight: 300 !important;
}

.modal-feedback {
    width: 55% !important;
    margin-left: auto;
    margin-right: auto;
}

.ant-modal .ant-modal-close svg{
    color: #fff !important;
    background-color: #000 !important;
}

@media (width < 900px) {
    .call-back-button {
        font-size: 14px;
        height: auto;
        padding-top: 7px;
        padding-bottom: 7px;
        margin-top: 10px;
        min-width: 0;
    }

    .socsety_mobile div {
        width: 70%;
        display: flex;
        justify-content: space-around;
    }
}

@media (width < 450px) {
    .socsety_mobile div {
        width: 100%;
    }
}