.cars {
    display: flex;
    width: 100%!important;
    flex-wrap: wrap;
    column-gap: calc((100% - (400px * 4)) / 3);
    justify-content: flex-start;
}

@media (max-width: 1750px) {
    .cars {
        column-gap: calc((100% - (400px * 3)) / 2);
    }
}

@media (max-width: 1250px) {
    .cars {
        column-gap: calc((100% - (350px * 3)) / 2);
    }
}

@media (max-width: 1100px) {
    .cars {
        column-gap: calc((100% - (300px * 3)) / 2);
    }
}

@media (max-width: 950px) {
    .cars {
        column-gap: calc((100% - (400px * 2)));
    }
}

@media (max-width: 850px) {
    .cars {
        column-gap: calc((100% - (350px * 2)));
    }
}

@media (max-width: 750px) {
    .cars {
        column-gap: calc((100% - (300px * 2)));
    }
}

@media (max-width: 650px) {
    .cars {
        column-gap: calc((100% - (250px * 2)));
    }
}

@media (max-width: 550px) {
    .cars {
        column-gap: calc((100% - (200px * 2)));
    }
}

@media (max-width: 450px) {
    .cars {
        justify-content: space-between;
        column-gap: calc((100% - (180px * 2)));
    }
}
