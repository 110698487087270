/* .gt-logo {
    width: 339px !important;
    height: 56px !important;
} */

footer {
    height: auto!important;
}

.icon {
    width: 42px;
    padding: 5px;
}

.footer-menu ul{
    width: 100%;
    min-width: 800px;
    max-width: 900px;
    display: flex;
    justify-content: center;
    background-color: transparent;
    font-size: 24px;
    font-weight: 300;
    color: #fff;
    border: none !important;
    padding-bottom: 50px;
}

.footer-menu span {
    color: #fff;
}

.footer-menu li:hover {
    color: #F67828 !important;
}

.footer-menu li::after {
    border-bottom-color: transparent !important;
}

.footer-menu a:hover {
    text-decoration: none;
}

.footer-menu .ant-menu-item-selected {
    color: #fff !important;
    background-color: transparent!important;
}

.footer-menu .ant-menu-item-selected::after {
    border-bottom-color: none !important
}

.policy {
    justify-content: center;
}

.policy a {
    font-size: 24px;
    font-weight: 300;
    color: #fff;
    opacity: 0.5
}

.policy a:hover {
    color: #fff;
    opacity: 0.5
}

.footer__phone {
    justify-content: end;
}

.footer-call-now {
    width: max-content;
    line-height: 46px;
}

.footer-call-now a {
    font-size: 24px;
    font-weight: 400;
    color: #fff;
}

.footer-call-now a:hover {
    color: #fff;
}

.footer-call-now a:active {
    color: #fff;
}

.footer-call-now a:focus {
    color: #fff;
}

@media (width < 1150px) {
    footer {
        padding: 0!important;
    }

    .footer-menu ul {
        padding-bottom: 20px;
    }

    .footer-menu span {
        font-size: 20px!important;
    }

    .policy a {
        font-size: 20px;
    }

    .footer__phone {
        min-width: 0!important;
    }

    .footer__phone a {
        font-size: 20px;
    }

    .footer__phone img {
        width: 25px!important;
    }

}

@media (width < 950px) {
    footer {
        height: auto!important;
    }

    .footer > div > div {
        width: 100%;
    }

    .footer-menu {
        display: flex;
        justify-content: flex-end;
    }

    .footer-menu > div {
        max-width: 35%;
    }

    .footer__phone {
        display: flex;
        justify-content: center!important;
    }

    .footer__phone {
        margin-bottom: 30px;
    }

    .footer__phone .ant-space a {
        font-size: 16px!important;
    }

    .footer__phone .ant-space img {
        height: 16px;
        width: 16px;
    }

    .footer-menu ul {
        flex-direction: column;
        width: 100%;
        justify-content: start;
        min-width: auto;
    }

    .footer-menu li {
        padding: 0!important;
        margin: 0!important;
        line-height: 1.5!important;
    }

    .footer-menu span {
        font-size: 18px!important;
        line-height: 1;
    }

    .policy a {
        font-size: 18px;
        margin-bottom: 30px;
    }

    .footer_logo_socsety {
        justify-content: space-between;
        margin-top: 20px;
    }

    .footer_logo_socsety > div:nth-child(1) {
        max-width: 180px !important;
    }

    .icon {
        width: 30px;
        padding: 0;
    }

}

@media (width < 600px) {
    .footer-menu {
        justify-content: start;
    }

    .footer-menu > div {
        max-width: 50%;
    }

    .footer-menu span {
        font-size: 16px!important;
    }

    .policy {
        justify-content: start;
    }

    .policy a {
        font-size: 18px;
    }

    .footer_logo_socsety > div:nth-child(1) {
        max-width: 180px !important;
    }

    .icon {
        width: 24px;
        padding: 0;
    }
}

@media (width < 450px) {
    footer {
        margin-top: 50px;
    }

    .footer__phone {
        margin-bottom: 30px;
    }

    .footer__phone .ant-space a {
        font-size: 14px!important;
    }

    .footer__phone .ant-space img {
        height: 14px;
        width: 14px;
    }

    .footer-menu span {
        font-size: 12px!important;
    }

    .policy a {
        font-size: 12px;
    }

    .icon {
        width: 20px;
    }
}