.main-carousel .carousel-item {
    height: 661px;
    width: 100%;
}

.main-carousel .carousel-slide {
    background-size: cover;
    position: relative;
    display: flex;
    align-items: flex-start;
    height: 100%;
    width: 100%;
}

.main-carousel .carousel-slide-1 {
    background-image: url('./static/main_carousel.png');
    padding-top: 78px;
    background-position: center center;
}

.main-carousel .slick-arrow,
.main-carousel .slick-arrow:hover,
.main-carousel .slick-arrow:focus {
    z-index: 1;
    width: 59px;
    height: 59px;
    border-radius: 50px;
    background-color: #fff;
    opacity: 0.5;
}

.main-carousel .slick-next {
    inset-inline-end: 25px;
}

.main-carousel .slick-next::before {
    content: url(static/arrow-carousel.png);
}

.main-carousel .slick-prev {
    inset-inline-start: 25px;
    transform: scaleX(-1);
}

.main-carousel .slick-prev::before {
    content: url(static/arrow-carousel.png);
}

.main-carousel h1,
.main-carousel h2 {
    max-width: 905px;
    font-size: 48px;
    font-weight: bold;
    padding-bottom: 22px;
}

.main-carousel .carousel-slide-1 span {
    font-size: 40px;
    line-height: 40px;
}

.main-carousel .carousel-slide-1 span::before {
    content: url(./static/point.png);
    padding-right: 12px;
}

.main-carousel .carousel__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 61px;
    font-size: 24px;
    font-weight: lighter;
    background-color: #36322F;
    color: #fff;
    border-radius: 10px;
    border: none;
    margin-top: 32px;
}

.main-carousel .carousel__button:hover {
    opacity: 0.7;
    text-decoration: none!important;
}

@media (max-width: 950px) {
    .main-carousel h1 {
        font-size: 40px;
    }

    .main-carousel .carousel-item {
        box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.5), 5px 0 5px -5px rgba(0, 0, 0, 0.5);
    }

}

@media (max-width: 650px) {
    .main-carousel .carousel-item {
        height: 440px;
        padding-top: 30px;
    }

    .main-carousel h1 {
        font-size: 20px;
        max-width: 350px;
        margin-bottom: 0;
        padding-bottom: 13px;
    }

    .main-carousel .carousel-slide-1 span {
        font-size: 18px;
        line-height: 1;
    }

    .main-carousel .carousel-slide-1 span::before {
        content: none;
    }

    .main-carousel .carousel__info {
        row-gap: 0;
    }

    .main-carousel .carousel__button {
        margin-top: 57px;
        margin-bottom: 60px;
        padding: 8px 12px;
        width: 200px;
        height: auto;
        font-size: 18px;
    }
}

@media (max-width: 450px) {
    .main-carousel .carousel-item {
        height: 360px;
        padding-top: 21px;
    }

    .main-carousel h1 {
        font-size: 16px;
        max-width: 272px;
        margin-bottom: 0;
        padding-bottom: 13px;
    }

    .main-carousel .carousel-slide-1 span {
        font-size: 14px;
        line-height: 1;
    }

    .main-carousel .carousel-slide-1 span::before {
        content: none;
    }

    .main-carousel .carousel__info {
        row-gap: 0;
    }

    .main-carousel .carousel__button {
        margin-top: 57px;
        margin-bottom: 76px;
        padding: 8px 12px;
        width: 150px;
        height: auto;
        font-size: 14px;
    }
}
