.header {
    padding-top: 38px;
    padding-bottom: 54px;
}

.header > div {
    display: flex;
    width: 100%;
    justify-content: space-between!important;
}

.main-menu {
    width: 100%;
    min-width: 550px;
    max-width: 600px;
    display: flex;
    justify-content: center;
    background-color: transparent;
    font-size: 24px;
    font-weight: 300;
    color: #fff;
}

.button__menu {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 36px;
    width: 36px!important;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.button__menu:focus,
.button__menu:focus-visible {
    outline: none!important;
}

.button__menu svg {
    width: 20px;
    height: 20px;
}

.main-menu span {
    color: #fff;
}

.main-menu li:hover {
    color: #F67828 !important;
}

.main-menu li::after {
    border-bottom-color: transparent !important;
}

.main-menu a:hover {
    text-decoration: none;
}

.main-menu .ant-menu-item-selected {
    color: #fff !important;
}

.main-menu .ant-menu-item-selected::after {
    border-bottom-color: none !important
}

.main-search {
    width: 100%;
    max-width: 600px;
    border: none;
    border-bottom: solid 1px #fff;
}

.main-search span {
    background-color: transparent;
    border: none;
}

.main-search span:hover {
    background-color: transparent;
}

.main-search span:focus-within {
    border: none;
    box-shadow: none;
    background-color: transparent;
}

.main-search input {
    background-color: transparent !important;
    color: #fff;
    font-size: 24px !important;
    font-weight: 400 !important;
}

.main-search input:focus {
    background-color: transparent !important;
    color: #fff;
}

.main-search input:focus-within {
    background-color: transparent !important;
    color: #fff;
}

.main-search button {
    font-size: 24px;
    font-weight: 400;
    background-color: transparent !important;
    border: 0px;
}

.main-search button:focus {
    outline: none;
}

.main-search .ant-input-group-addon:focus-within * {
    outline: none !important;
    transition: none !important;
}

.call-back-button {
    border: 0px !important;
    width: 100%;
    min-width: 220px;
    height: 61px;
    font-size: 24px;
    color: #fff;
    padding: 8px 15px;
    font-weight: 400;
    background: #F67828;
}

.call-back-button a:hover {
    color: #000;
    text-decoration: none;
}

.call-back-button:hover {
    background-color: #fff !important;
    color: #000 !important;
}

.call-now {
    width: max-content;
}

.call-now a {
    font-size: 24px;
    font-weight: 400;
    color: #fff;
}

.call-now a:hover {
    color: #fff;
}

.call-now a:active {
    color: #fff;
}

.call-now a:focus {
    color: #fff;
}

header ul.ant-menu {
    border: none;
}

@media (width < 1350px) {
    .header {
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .header__phone {
        justify-content: center;
    }

    .header__phone img{
        height: 24px;
        width: 24px;
    }

    .header__phone a {
        font-size: 24px;
        height: 18px;
    }

    .header__phone > div {
        line-height: 1;
    }

    .button__menu {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 36px;
        width: 50px!important;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    
    .button__menu:focus,
    .button__menu:focus-visible {
        outline: none!important;
    }
    
    .button__menu svg {
        width: 30px;
        height: 30px;
    }

    .ant-drawer-content-wrapper {
        width: 300px!important;
    }

    .ant-drawer-content-wrapper .ant-drawer-content {
        background-color: rgba(12, 10, 8, 0.7)!important;
        backdrop-filter: blur(5px);
    }

    .ant-drawer-content-wrapper .ant-drawer-title {
        color:#fff;
    }

    .ant-drawer-content-wrapper ul {
        background: transparent;
    }

    .ant-drawer-content-wrapper ul li {
        color: #fff!important;
        padding: 0!important;
        background-color: transparent!important;
    }

    .ant-drawer-body {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .ant-drawer-content-wrapper .footer__phone {
        min-width: 0!important;
    }

}

@media (width < 750px) {
    .header {
        padding-top: 30px;
        padding-bottom: 25px;
    }

    .header__phone {
        justify-content: center;
    }

    .header__phone img{
        height: 18px;
        width: 18px;
    }

    .header__phone a {
        font-size: 18px;
        height: 18px;
    }

    .header__phone > div {
        line-height: 1;
    }

    .button__menu {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 36px;
        width: 36px!important;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    
    .button__menu:focus,
    .button__menu:focus-visible {
        outline: none!important;
    }
    
    .button__menu svg {
        width: 20px;
        height: 20px;
    }

}

@media (width < 450px) {
    .header {
        padding-top: 24px;
        padding-bottom: 18px;
    }

    .header__phone img{
        height: 12.5px;
        width: 12.5px;
    }

    .header__phone a {
        font-size: 12px;
        height: 14px;
    }

    .ant-drawer-content-wrapper {
        width: 180px!important;
    }

}