.feedback {
    background-image: url(static/feedback-bg.webp);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100% !important;
    height: 800px;
}


.feedback__text span {
    font-size: 46px;
    font-weight: 200;
    line-height: 50px;
    color: #fff;
}

.feedback__form input {
    height: auto;
    margin-bottom: 16px;
    font-size: 24px;
    text-align: center;
    width: 100%;
}

.feedback__form .submit-button {
    width: 100% !important;
    height: auto;
    font-size: 24px;
    font-weight: 300;
    background-color: #F67828;
}

.feedback__form button:hover {
    background-color: #fff !important;
    color: #000 !important;
}


.feedback__text span {
    color: #FFF;
    font-family: Roboto;
    font-size: 48px;
    font-weight: 300;
    line-height: 60px;
}

.colortext {
    color: #F67828 !important;
}

.feedback__form input {
    padding: 10px 60px;
    font-size: 24px;
    font-weight: 300;
    background-color: #fff !important;
    border: 2px solid #FFF;
}

.feedback__form input:focus {
    background-color: #FFF;
    color: #6D6D6D;
    border: 2px solid #FFF;
}

.feedback__form input:hover {
    border: 2px solid #FFF;
}

.feedback__form input::-webkit-input-placeholder {
    color: #6D6D6D;
    opacity: 0.7;
}

.feedback__form input::-moz-placeholder {
    color: #FFF;
    opacity: 0.7;
}

.feedback__form input:-moz-placeholder {
    color: #FFF;
    opacity: 0.7;
}

.feedback__form input:-ms-input-placeholder {
    color: #FFF;
    opacity: 0.7;
}

.phone-input {
    border-radius: 5px;
}

.submit-button {
    border: 0px !important;
    width: 100%;
    height: auto;
    font-size: 22px;
    color: #fff;
    padding: 8px 15px;
    text-transform: capitalize;
    font-weight: 300;
    background: #F67828;
}

.submit-button:focus {
    outline: none;
}

.submit-button:hover {
    background-color: #36322F !important;
}

@media (width < 1250px) {
    .feedback {
        height: 400px;
        padding-top: 170px;
        padding-bottom: 40px;
    }

    .feedback__form input,
    .feedback button[type=submit] {
       font-size: 24px;
       line-height: 24px;
    }

    .feedback__form .submit-button {
        height: auto;
    }

    .feedback__text span {
        font-size: 28px;
        line-height: 28px;
    }

    .feedback .ant-form-item {
        margin-bottom: 5px;
    }

    .feedback__form input {
        height: auto;
        margin-bottom: 7px;
        font-size: 16px;
        text-align: center;
        width: 100%;
    }

    .feedback-checkbox {
        font-size: 12px;
        opacity: 0.6;
        align-items: center;
    }

    .feedback__form button span {
        font-size: 24px;
        line-height: 24px;
    }
}

@media (width < 950px) {
    .feedback {
        height: 300px;
        padding-top: 160px;
        padding-bottom: 30px;
    }

    .feedback__form input,
    .feedback button[type=submit] {
       font-size: 20px;
       line-height: 20px;
    }

    .feedback__form .submit-button {
        height: auto;
    }

    .feedback__text {
        padding-bottom: 52px;
    }

    .feedback__text span {
        font-size: 24px;
        line-height: 24px;
    }

    .feedback .ant-form-item {
        margin-bottom: 5px;
    }

    .feedback__form input {
        height: auto;
        margin-bottom: 7px;
        font-size: 16px;
        text-align: center;
        width: 100%;
    }

    .feedback-checkbox {
        font-size: 14px;
        opacity: 0.6;
    }

}

@media (width < 750px) {
    .feedback {
        position: relative;
        flex-direction: column;
        padding-top: 100px;
        background-image: none;
        background-size: 70% auto;
        height: auto;
        justify-content: flex-start;
        min-height: 300px;
    }

    .feedback::before {
        /* content: ''; */
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200%;
        background-image: url('./static/feedback-bg-mobile.jpg');
        background-size: cover;
        background-position: center;
        z-index: 0;
    }

    .feedback__form input,
    .feedback button[type=submit] {
       padding-top: 8px;
       padding-bottom: 8px;
       width: 100%;
       font-size: 24px;
       line-height: 24px;
       height: auto;
    }

    .feedback__text {
        max-width: 100%;
        padding-bottom: 52px;
        flex: none;
    }

    .feedback__text span {
        font-size: 26px;
        line-height: 26px;
    }

    .feedback__text + div {
        max-width: 100%;
    }

    .feedback-checkbox {
        font-size: 18px;
        display: flex;
        align-items: center;
        opacity: 0.6;
    }

    .feedback__product > div {
        margin-top: 0;
        padding-top: 0;
    }

}

@media (width < 450px) {

    .feedback {
        padding-top: 60px;
    }

    .feedback__form input,
    .feedback button[type=submit] {
       font-size: 14px;
       line-height: 14px;
    }

    .feedback__text span {
        font-size: 20px;
        line-height: 20px;
    }

    .feedback-checkbox {
        font-size: 10px;
        display: flex;
        align-items: center;
        opacity: 0.6;
    }

}